import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Converter from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Helmet } from "react-helmet";

Sentry.init({ dsn: "https://4c7efddb7f164cc7a06750b860007735@o426534.ingest.sentry.io/5368570" });

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Currency Converter Tool</title>
      <meta name="description" content="Currency convert tool"></meta>
      <base target="_parent"></base>
      <link rel="shortcut icon" href="https://currencypartners.co.za/wp-content/themes/currency-partners/img/favicon.png"></link>
    </Helmet>
    <Converter />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
