import React, { Component } from "react";
import axios from "axios";
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from './images/Currency-Partners-logo.svg';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from 'react-ga';

// Initialise Sentry with Data Source Name(dsn)
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // debug: true,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
});

console.log(process.env.REACT_APP_SENTRY_DSN)
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#9f7e55',
            contrastText: 'white',
        },
    },
});


const styles = theme => ({
    root: {
        padding: 25,
        border: 'solid #9f7e55 2px',
        textAlign: 'center',
        overflow: 'hidden',
    },
    logo: {
        marginBottom: 15,
    },
    heading: {
        lineHeight: 2,
    },
    copy: {
        fontSize: '12px',
    },
    anchor_tag: {
        textDecoration: 'none',
        fontWeight: 600,
        position: 'relative',
        top: 5,
        color: '#000',
    },
    cp_primary: {
        backgroundColor: '#9f7e55',
        color: '#fff',
        fontWeight: 600,
    },
    'MuiInput-underline::after': {
        display: "none",
    },
    first_button: {
        fontWeight: 600,
    },
    second_button: {
        fontWeight: 600,
        marginTop: 20,
    },
    paper: {
        marginTop: theme.spacing(8),
        border: 'solid #9f7e55 2px ',
    },
    'MuiSelect-icon': {
        color: '#9f7e55 !important',
    },
    form: {
        width: '100%',
        marginBottom: 10,
    },
    input: {
        borderRadius: 0,

    },
    select: {
        width: '100%',
        marginBottom: '15px',
    },
    link: {
        color: '#9f7e55',
        textDecoration: 'underline',
    },
    amount: {
        width: '100%',
        marginBottom: '15px',
    },
    cssOutlinedInput: {
        "border-radius": "0 !important",
        "&$cssFocused $notchedOutline": {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    notchedOutline: {
        borderColor: "#9f7e55 !important",
    },
});


class Converter extends Component {
    state = {
        result: null,
        fromCurrency: "ZAR",
        toCurrency: "USD",
        amount: 1,
        currencies: [],
        showResult: false,
    };

    // Initializes the currencies with values from the api
    componentDidMount() {
        axios
            .get("https://currencypartners.netlify.app/currencies.json")
            .then(response => {
                // Initialized with 'EUR' because the base currency is 'EUR'
                // and it is not included in the response
                const currencyAr = ["EUR"]
                for (const key in response.data.rates) {
                    currencyAr.push(key)
                }
                this.setState({ currencies: currencyAr.sort() })
            })
            .catch(err => {
                console.log("Oops", err.message);
            });
    }

    handleHide = () => {
        this.setState({
            showResult: false,
        })
    }
    goHome = () => {
        window.open("https://currencypartners.co.za", "_blank")
    }
    // Event handler for the conversion
    convertHandler = () => {
        if (this.state.fromCurrency !== this.state.toCurrency) {
            axios
                .get(`https://xecdapi.xe.com/v1/convert_to.json/?from=${this.state.toCurrency}&to=${this.state.fromCurrency}&amount=${this.state.amount}&decimal_places=2`, {
                    headers: {
                        'Authorization': process.env.REACT_APP_XE_AUTH
                    }
                })
                .then(response => {
                    const result = response.data.from.map(re => re.mid)
                    console.log(result[0])
                    this.setState({ result: result[0].toFixed(2) })
                    this.setState({ showResult: true })
                })
                .catch(err => {
                    console.log("Opps", err.message);
                });
            console.log(this.state.fromCurrency + '-' + this.state.toCurrency)
            ReactGA.event({
                category: 'Form',
                action: 'Form Submit',
                transport: 'beacon'
            });
        } else {
            this.setState({ result: "You cant convert the same currency!" })
        }
    };
    // Updates the states based on the dropdown that was changed
    selectHandler = (event) => {
        if (event.target.name === "from") {
            this.setState({ fromCurrency: event.target.value })
        }
        if (event.target.name === "to") {
            this.setState({ toCurrency: event.target.value })
        }
    }

    render() {
        const { classes } = this.props;
        { ReactGA.initialize('G-LVKKZKJY8H'); }
        { ReactGA.pageview(window.location.pathname + window.location.search); }
        return (
            <ThemeProvider theme={theme}>
                <Card className={classes.root} variant="outlined" textAlign="center" style={{ "border-radius": "0px" }}>
                    <Grid container direction="column" justify="center" >
                        <form className={classes.form} noValidate autoComplete="off">
                            <Logo className={classes.logo} />
                            <Grid item xs={12} sm={12}>
                                <Typography component="h1" variant="h5" className={classes.heading}>
                                    Currency Converter
                                </Typography>
                            </Grid>
                            <Grid>
                                <TextField className={classes.amount} name="amount" type="number" variant="outlined"
                                    style={{ "border-radius": "0px" }}
                                    value={this.state.amount}
                                    onClick={this.handleHide}
                                    id="mui-theme-provider-standard-input"
                                    onChange={event => this.setState({ amount: event.target.value })}
                                    InputProps={{
                                        classes: {
                                            root: classes.cssOutlinedInput,
                                            focused: classes.cssFocused,
                                            notchedOutline: classes.notchedOutline
                                        },
                                    }}
                                />
                            </Grid>
                            {/* {Sentry.captureMessage("Something went wrong")} */}
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.select}>
                                        <Select
                                            style={{ "border-radius": "0px" }}
                                            onChange={(event) => this.selectHandler(event)}
                                            onClick={this.handleHide}
                                            name="from"
                                            InputProps={{
                                                classes: {
                                                    root: classes.root,
                                                    notchedOutline: classes.notchedOutline
                                                }
                                            }}
                                            className={classes.select2}
                                            value={this.state.fromCurrency}>
                                            {this.state.currencies.map(cur => (
                                                <MenuItem value={cur} key={cur}>{cur}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl variant="outlined" className={classes.select}>
                                        <Select
                                            style={{ "border-radius": "0px" }}
                                            name="to"
                                            onChange={(event) => this.selectHandler(event)}
                                            onClick={this.handleHide}
                                            value={this.state.toCurrency}>
                                            {this.state.currencies.map(cur => (
                                                <MenuItem value={cur} key={cur}>{cur}</MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Button
                                style={{ "border-radius": "0px", width: "100%" }}
                                fullWidth
                                variant="contained"
                                className={classes.cp_primary}
                                onClick={this.convertHandler}>
                                CONVERT
                            </Button>
                        </form>
                        <Sentry.ErrorBoundary fallback={"An error has occured"}>
                            {this.state.showResult ?
                                <div>
                                   
                                    <h2>{this.state.result} {this.state.toCurrency}</h2>
                                </div> : null
                            }
                        </Sentry.ErrorBoundary>
                        <Grid item xs={12}>
                            <Typography className={classes.copy}>

                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </ThemeProvider >
        );
    }
}

export default withStyles(styles, { withTheme: true })(Converter);
